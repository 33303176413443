import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import Layout from "../../components/layout"
import SummaryBanner from "../../components/summaryBanner/"
import Tabs from "../../components/tabs/"
import PdfItem from '../../components/pdfItem/'
import SEO from "../../components/seo"
import { navigate } from '@reach/router'
import withLocation from '../../HOC/withLocation.js'
import { getById } from '../../reducers/actions/project.js'
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from 'draft-js'

const Details = ({response, id}) => {
  const details = response ?
    convertToHTML(convertFromRaw(JSON.parse(response.details))) : ''
  return (
   <section>
     <div className="content" dangerouslySetInnerHTML={{__html: details}}></div>
      <button
        className={'primary'}
        onClick={() => navigate('/proposal', {state:{id}})}
      >
        Postular
      </button>
    </section>
)}

const Attachments = () => (
  <section>
    {[1,2,3,4,5].map(key => <PdfItem key={key} filename={'ejemplo.pdf'}/>)}
  </section>
)

const ProjectDetail = ({search, location}) => {

  const project  = useSelector(state => state.projectReducer.getProjectById)
  const dispatch = useDispatch()

  const id = search.id
  const [isFirstRender, setIsFirstRender] = useState(true)

  const initialTabs = {
    Detalles: true,
    Adjuntos: false
  }
  if(location.state && location.state.tab) { 
    Object.keys(initialTabs).forEach(key =>  initialTabs[key] = key === location.state.tab)
  }
 
  const [tabs, setTabs] = useState(initialTabs)
  const onChangeTab = (tab) => {
    setTabs(
      Object.keys(tabs).reduce((acc, current) => ({...acc, [current]: current === tab}), {})
    )
  }

  useEffect(() => {
    if(id && isFirstRender) {
      setIsFirstRender(false)
      getById(dispatch)(id)
    }
  }, [project])

  const data = project.response && {
    title: project.response.name,
    text: project.response.summary,
    contact: {
      Teléfono: project.response.user.contact.phone,
      Email: project.response.user.contact.email
    },
    highlights: {
      'Proyectos creados': project.response.user.nProjects,
      'Calificación': project.response.user.score,
      'Presupuesto': project.response.budget
    }
  }

  return (
    <Layout>
      <SEO title="Projecto" />
      { data && <SummaryBanner {...data}/>}
      <div style={{margin: '2.5rem 0'}}>
        <Tabs tabs={tabs} onChangeTab={onChangeTab}/>
      </div>
      {tabs['Detalles'] && project.response && <Details {...project} id={id}/>}
      {tabs['Adjuntos'] && project.response && <Attachments {...project} />}
    </Layout>
  )
}

export default withLocation(ProjectDetail)
